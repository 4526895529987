<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="ApplyCardContent">
      <div class="ApplyCardContainer">
        <div class="backContainer" @click="backBtn">
          <a-icon type="arrow-left" /> back
        </div>
        <div class="applyContent">
          <div class="ApplyCardTitle">About the Card</div>
          <div class="cardBox">
            <div class="cardBoxList">
              <div class="title">Card Type</div>
              <div class="value" v-if="!virtualCard">
                Plastic Card (Plastic)
              </div>
              <div class="value" v-else-if="cardType === '0'">Virtual Visa Card</div>
              <div class="value" v-else-if="cardType === '1'">
                Crypto Card (Virtual)
              </div>
            </div>
            <div class="cardBoxList">
              <div class="title">Currency</div>
              <div class="value">{{ currency || 'USD' }}</div>
            </div>
            <div class="cardBoxList" v-if="!virtualCard">
              <div class="title">Card Application Fee</div>
              <div class="value"> {{ cardApplicationFee || 5 }} {{cardFeeCurrency||'USD'}}</div>
            </div>
            <div class="cardBoxList" v-else-if="cardType === '0'">
              <div class="title">Card Application Fee</div>
              <div class="value"> {{ cardApplicationFee || 5 }} {{cardFeeCurrency||'USD'}}</div>
            </div>
            <div class="cardBoxList" v-if="cardType === '0'">
              <div class="title">Monthly Fee</div>
              <div class="value">0 USD</div>
            </div>
            <div class="cardBoxList" v-if="cardType === '0'">
              <div class="title">Max. Balance Limit</div>
              <div class="value">100,000 USD</div>
            </div>
          </div>
          <p class="pSty" v-if="cardType === '0'">
            The Inst card is a prepaid card that can be used for online
            shopping, airline tickets, etc. in 200+ countries. It supports
            deposit in the form of digital currencies such as USDT, BTC, and
            ETH. Users need to perform KYC verification to ensure compliance
            with international anti-money laundering regulations.
          </p>
          <p class="pSty" v-if="cardType === '1'">
            This crypto card can be used for online shopping, airline tickets,
            etc. in 200+ countries. Before making purchases with USD, it is
            required to deposit equivalent amount of cryptos such as USDT and
            NEO. Card holders must perform KYC verification to ensure compliance
            with international anti-money laundering regulations.
          </p>
          <h4>Attentive Service</h4>
          <p class="pSty">
            Inst only requires credentials and documents at hand for the card
            application. Verified users can access all features using the mobile
            client, including customer service for any issues such as abnormal
            transactions or any asset losses.
          </p>
          <h4>Security Guaranteed</h4>
          <p class="pSty">
            Inst accepts the unified financial supervision of the European
            Central Bank and the requirements of the European Union Payment Act
            (PSD2) to ensure the safety of funds. Inst is fully compliant with
            European Union's General Data Protection Regulation (GDPR) to ensure
            user privacy and data security. Users can report any form of losses
            and freeze the account with just one click. The security standards
            are consistent with mainstream commercial banks.
          </p>
          <h4>Multiple Tokens</h4>
          <p class="pSty">
            Inst supports popular tokens including BTC, ETH, USDT etc. and will
            add more to the list. Users can easily handle their international
            businesses and overseas travels.
          </p>
          <h4>Multiple Scenarios</h4>
          <p class="pSty">
            The card can be used for multiple scenarios including online
            shopping, flight purchase, etc. in 200+ countries.
          </p>
          <div class="cardDetailsImg">
            <img src="@/assets/carousel4.svg" alt="" />
            <img src="@/assets/carousel5.svg" alt="" />
            <img src="@/assets/carousel6.svg" alt="" />
          </div>
          <h2>Application Requirement</h2>
          <p class="pSty">- Passport</p>
          <p class="pSty">- Residential Address</p>
          <h2>Transaction Rules</h2>
          <p class="pSyDe" v-if="!virtualCard">
           <span class="pSty">Deposit Transaction Fee:</span> {{depositTransactionFee||2 }}%
          </p>
          <p class="pSyDe" v-else-if="cardType === '0' && cardTypeId === '60000004'">
            <span class="pSty">Deposit Transaction Fee:</span>
            {{depositTransactionFee||2.5 }}% (Minimum Fee: 1 USD)
          </p>
          <p class="pSyDe" v-else-if="cardType === '0' && cardTypeId !== '60000004'">
            <span class="pSty">Deposit Transaction Fee:</span>{{depositTransactionFee|| 2}}%
          </p>
          <p class="pSyDe" v-if="cardType === '0'">
            <span class="pSty">Daily Deposit Limit:</span> 2000.00 USD
          </p>
          <p class="pSyDe" v-if="cardType === '0'">
            <span class="pSty">Account Balance Limit:</span> 100000.00 USD
          </p>
          <p class="pSyDe" v-if="cardType === '0'">
            <span class="pSty">Monthly Transaction Limit:</span> 100000.00 USD
          </p>
          <p class="pSyDe">
            <span class="pSty">Daily Payment Limit:</span> 10000.00 USD
          </p>
        </div>
      </div>
      <NewFooter />
    </div>
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'ApplyCard',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
  },
  data() {
    return {
      cardType: null,
      cardTypeId: null,
      virtualCard: null,
      cardApplicationFee: '',
      cardFeeCurrency: '',
      depositTransactionFee: '',
      currency: '',
    };
  },
  created() {
    const {
      cardType,
      ct,
      virtualCard,
      cardApplicationFee,
      depositTransactionFee,
      currency,
      cardFeeCurrency,
    } = this.$route.query;
    this.cardType = cardType;
    console.log('virtualCard', virtualCard, typeof virtualCard);
    this.virtualCard = virtualCard !== '1';
    this.cardTypeId = ct;
    if (cardApplicationFee) {
      this.cardApplicationFee = cardApplicationFee;
    }
    if (cardFeeCurrency) {
      this.cardFeeCurrency = cardFeeCurrency;
    }
    if (depositTransactionFee) {
      this.depositTransactionFee = depositTransactionFee;
    }
    if (currency) {
      this.currency = currency;
    }
  },
  methods: {
    backBtn() {
      this.$router.push({
        path: '/my',
        query: {
          tab: 'myCard',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ApplyCardContent {
  @media (max-width: 960px) {
    margin-top: 24px;
  }

  .ApplyCardContainer {
    max-width: 1280px;
    padding: 80px 24px 0;
    margin: auto;
    margin: 24px auto;
    min-height: calc(100vh - 100px);

    @media (max-width: 960px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    .backContainer {
      font-size: 18px;
      cursor: pointer;
      font-weight: 500;
    }

    .applyContent {
      margin: auto;

      @media (max-width: 960px) {
        margin-top: 24px;
      }

      .ApplyCardTitle {
        font-family: NoumenaFont-Bold;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        opacity: 0.8;
        color: #000;
      }

      .cardBox {
        background: rgba(0, 69, 255, 0.03);
        padding: 30px;
        display: flex;
        margin-top: 60px;
        margin-bottom: 40px;

        @media (max-width: 960px) {
          flex-direction: column;
        }

        .cardBoxList {
          flex: 1;

          @media (max-width: 960px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 10px 0;
          }

          .title {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            opacity: 0.6;
            margin-bottom: 15px;
          }

          .value {
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            color: #0045ff;
          }
        }
      }

      .cardDetailsImg {
        margin: 60px auto;
        text-align: center;

        img {
          width: 800px;

          @media (max-width: 960px) {
            width: 100%;
          }
        }
      }

      .pSyDe {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #0045ff;

        .pSty {
          width: 240px;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          opacity: 0.6;
          margin-top: 10px;
          color: #000;
          margin-right: 40px;
          display: inline-block;

          @media (max-width: 960px) {
            width: 200px;
            margin-right: 0px;
          }
        }
      }

      .pSty {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        opacity: 0.6;
        margin-top: 10px;
      }

      h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-top: 30px;
      }

      h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        margin-top: 30px;
      }
    }
  }
}</style>
